import React, { Fragment } from 'react';
import { SFChip } from '../../SFChip/SFChip';

export interface SFAutocompleteChipRenderProps<T> {
  values: T[];
  disabled: boolean;
  getValueColor?: (value: T) => string;
  getValueText?: (value: T) => string;
  onDelete: (input: T, index?: number) => void;
}

export function SFAutocompleteChipRender<T>({
  values,
  disabled,
  getValueColor,
  getValueText,
  onDelete
}: SFAutocompleteChipRenderProps<T>): React.ReactElement<
  SFAutocompleteChipRenderProps<T>
> {
  return (
    <Fragment>
      {values.map((value: T, index: number) => {
        const text = getValueText
          ? getValueText(value)
          : ((value as unknown) as string);
        const color = getValueColor ? getValueColor(value) : 'default';
        return (
          <SFChip
            key={`${text}-${index}`}
            deleteable
            sfColor={color}
            variant='outlined'
            size='small'
            label={text}
            disabled={disabled}
            onDelete={(): void => onDelete(value, index)}
          />
        );
      })}
    </Fragment>
  );
}
