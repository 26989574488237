import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import {
  withStyles,
  Theme,
  makeStyles,
  useTheme
} from '@material-ui/core/styles';
import Chip, { ChipProps } from '@material-ui/core/Chip';
import {
  SFBlue,
  SFGrey,
  SFRed,
  SFSurfaceLight,
  SFTextBlack
} from '../../SFColors/SFColors';
import { SFIconButton, SFIconButtonProps } from '../SFIconButton/SFIconButton';
import { hexToRgba } from '../../Helpers';

const StyledChip = withStyles(() => ({
  root: {
    fontWeight: 400,
    fontSize: '13px',
    maxWidth: '100%'
  },
  label: {
    lineHeight: '24px'
  },
  labelSmall: {
    lineHeight: '20px'
  }
}))(Chip);

function getBackgroundColor(props: SFChipProps, isLight: boolean): string {
  if (props.variant === 'outlined') {
    if (
      !props.disabled &&
      !props.hasError &&
      props.sfColor !== 'primary' &&
      props.sfColor !== 'default'
    ) {
      return isLight
        ? (hexToRgba(props.sfColor, 0.1) as string)
        : (hexToRgba(props.sfColor, 0.2) as string);
    } else {
      return 'transparent';
    }
  } else {
    if (props.disabled) {
      return isLight ? SFGrey[100] : SFGrey[600];
    } else if (props.hasError) {
      return isLight ? SFRed[700] : SFRed[200];
    } else if (props.sfColor === 'primary') {
      return isLight ? SFBlue[500] : SFBlue[200];
    } else if (props.sfColor === 'default') {
      return isLight ? SFGrey[100] : SFGrey[500];
    } else {
      return props.sfColor;
    }
  }
}

function getColor(props: SFChipProps, isLight: boolean): string {
  if (props.disabled) {
    return isLight ? SFGrey[400] : SFGrey[600];
  } else if (props.hasError) {
    if (props.variant === 'outlined') {
      return isLight ? SFRed[700] : SFRed[200];
    } else {
      return isLight ? SFSurfaceLight : SFGrey[900];
    }
  }

  if (props.sfColor === 'primary') {
    if (props.variant === 'outlined') {
      return isLight ? SFBlue[500] : SFBlue[200];
    } else {
      return isLight ? SFSurfaceLight : SFGrey[900];
    }
  } else if (props.sfColor !== 'default') {
    if (props.variant === 'outlined') {
      return isLight ? SFGrey[900] : SFGrey[50];
    } else {
      return isLight ? SFSurfaceLight : SFGrey[900];
    }
  }

  return isLight ? SFGrey[900] : SFGrey[50];
}

function getActiveBackgroundColor(
  props: SFChipProps,
  isLight: boolean
): string {
  if (props.variant === 'outlined') {
    if (props.hasError) {
      return isLight ? 'rgba(249, 220, 222, 0.4)' : 'rgba(240, 168, 173, 0.2)';
    } else if (props.sfColor === 'primary') {
      return isLight ? 'rgba(204, 235, 255, 0.4)' : 'rgba(128, 198, 255, 0.2)';
    } else if (props.sfColor === 'default') {
      return isLight ? 'rgba(204, 204, 204, 0.3)' : 'rgba(128, 128, 128, 0.3)';
    } else {
      return getBackgroundColor(props, isLight);
    }
  } else {
    if (props.hasError) {
      return isLight ? SFRed[800] : SFRed[300];
    } else if (props.sfColor === 'primary') {
      return isLight ? SFBlue[700] : SFBlue[300];
    } else if (props.sfColor === 'default') {
      return isLight ? SFGrey[200] : SFGrey[50];
    } else {
      return getBackgroundColor(props, isLight);
    }
  }
}

function getBorderColor(props: SFChipProps, isLight: boolean): string {
  if (props.variant === 'default') {
    return 'auto';
  } else {
    if (props.disabled) {
      return isLight ? SFGrey[200] : SFGrey[700];
    } else if (props.hasError) {
      return isLight ? SFRed[700] : SFRed[200];
    } else if (props.sfColor === 'primary') {
      return isLight ? SFBlue[500] : SFBlue[200];
    } else if (props.sfColor !== 'default') {
      return props.sfColor;
    } else {
      return isLight ? SFGrey[400] : SFGrey[500];
    }
  }
}

function getIconFillColor(props: SFChipProps, isLight: boolean): string {
  if (props.disabled) {
    return isLight ? SFGrey[400] : SFGrey[600];
  }

  if (props.variant === 'outlined') {
    if (props.hasError) {
      return isLight ? SFRed[700] : SFRed[200];
    } else if (props.sfColor === 'primary') {
      return isLight ? SFBlue[500] : SFBlue[200];
    } else if (props.sfColor !== 'default') {
      return isLight ? SFGrey[900] : SFGrey[50];
    } else {
      return isLight ? SFGrey[400] : SFGrey[500];
    }
  } else {
    if (props.hasError || props.sfColor !== 'default') {
      return isLight ? SFSurfaceLight : SFTextBlack;
    } else {
      return isLight ? SFGrey[400] : SFGrey[600];
    }
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: (props: SFChipProps): string =>
      props.fullWidth ? 'flex' : 'inline-flex',
    justifyContent: (props: SFChipProps): string =>
      props.fullWidth ? 'space-between' : 'auto',
    width: (props: SFChipProps): string => (props.fullWidth ? '100%' : 'auto'),

    backgroundColor: (props: SFChipProps): string =>
      getBackgroundColor(props, theme.palette.type === 'light'),

    '&.MuiChip-deletable.MuiChip-outlined:focus, &.MuiChip-deletable:focus': {
      backgroundColor: (props: SFChipProps): string =>
        getBackgroundColor(props, theme.palette.type === 'light')
    },

    '&:active': {
      backgroundColor: (props: SFChipProps): string =>
        getActiveBackgroundColor(props, theme.palette.type === 'light')
    },

    borderColor: (props: SFChipProps): string =>
      getBorderColor(props, theme.palette.type === 'light'),

    color: (props: SFChipProps): string =>
      getColor(props, theme.palette.type === 'light')
  }
}));

export interface SFChipProps extends ChipProps {
  sfColor: 'primary' | 'default' | string;
  deleteable?: boolean;
  deleteButtonProps?: Partial<Omit<SFIconButtonProps, 'sfIcon' | 'sfSize'>>;
  hasError?: boolean;
  fullWidth?: boolean;
}

export const SFChip = ({
  deleteable,
  sfColor,
  hasError,
  fullWidth,
  deleteButtonProps,
  ...props
}: SFChipProps): React.ReactElement<SFChipProps> => {
  const classes = useStyles({
    deleteable,
    sfColor,
    hasError,
    fullWidth,
    ...props
  });
  const theme = useTheme();
  const iconColor = getIconFillColor(
    { deleteable, sfColor, hasError, ...props },
    theme.palette.type === 'light'
  );

  return (
    <FormControl fullWidth={fullWidth}>
      <StyledChip
        {...props}
        role='none'
        classes={{ root: classes.root }}
        label={props.label}
        size={props.size}
        variant={props.variant}
        disabled={props.disabled}
        clickable={false}
        deleteIcon={
          <SFIconButton
            {...deleteButtonProps}
            aria-label={deleteButtonProps?.['aria-label'] || 'Remove chip'}
            sfColor={iconColor}
            sfIcon='Close'
            sfSize='tiny'
          />
        }
        onDelete={deleteable ? props.onDelete : undefined}
      />
    </FormControl>
  );
};
