import React, { ChangeEvent } from 'react';
import {
  AutocompleteRenderInputParams,
  AutocompleteInputChangeReason,
  AutocompleteChangeReason
} from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
import { SFAutocompleteChipRender } from './SFAutocompleteChipRender/SFAutocompleteChipRender';
import {
  minWidthInputSize,
  SFAutocompleteInput
} from './SFAutocompleteInput/SFAutocompleteInput';
import { StyledAutocomplete } from '../SFAutocomplete/SFAutocomplete';

export const StyledAutocompleteChip = withStyles({
  option: {
    padding: '6px 24px'
  },
  endAdornment: {
    display: 'none'
  },
  inputRoot: {
    height: 'auto'
  }
})(StyledAutocomplete);

export interface SFAutocompleteChipProps<T> {
  value: T[];
  inputMinWidth?: minWidthInputSize;
  label: string;
  error?: boolean;
  helperText?: string;
  options: T[];
  disabled?: boolean;
  required?: boolean;
  renderOption?: (option: T) => React.ReactNode;
  getValueText?: (value: T) => string;
  getValueColor?: (value: T) => string;
  getOptionLabel?: (option: T) => string;
  getOptionSelected?: (option: T, value: T) => boolean;
  onChange: (newValue: T[]) => void;
}

export function SFAutocompleteChip<T>({
  inputMinWidth = 30,
  label,
  error = false,
  helperText,
  options = [],
  value = [],
  disabled = false,
  required = false,
  getValueColor,
  getValueText,
  getOptionLabel,
  getOptionSelected,
  renderOption,
  onChange
}: SFAutocompleteChipProps<T>): React.ReactElement<SFAutocompleteChipProps<T>> {
  const [inputValue, setInputValue] = React.useState<string>('');

  const onDelete = (input: T): void => {
    const index: number = value.indexOf(input);
    const values = [...value.slice(0, index), ...value.slice(index + 1)];
    onChange(values);
  };

  const onInputChange = (
    _event: ChangeEvent,
    input: string,
    reason: AutocompleteInputChangeReason
  ): void => {
    if (reason === 'reset') {
      setInputValue('');
    } else {
      setInputValue(input);
    }
  };

  const onAutoCompleteChange = (
    _event: ChangeEvent,
    value: T[],
    reason: AutocompleteChangeReason
  ): void => {
    if (reason === 'select-option') {
      onChange(value);
    }
  };

  return (
    <StyledAutocompleteChip
      openOnFocus
      disabled={disabled}
      clearOnBlur
      options={options}
      multiple
      fullWidth
      value={value}
      inputValue={inputValue}
      onInputChange={onInputChange}
      onChange={onAutoCompleteChange}
      filterSelectedOptions
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      renderOption={renderOption}
      renderTags={(value: T[]): JSX.Element => (
        <SFAutocompleteChipRender
          disabled={disabled}
          values={value}
          getValueColor={getValueColor}
          getValueText={getValueText}
          onDelete={onDelete}
        />
      )}
      renderInput={(params: AutocompleteRenderInputParams): JSX.Element => (
        <SFAutocompleteInput
          {...params}
          rows={1}
          label={label}
          error={error}
          helperText={helperText}
          required={required}
          minWidth={
            inputMinWidth === 'full-width'
              ? '100%'
              : inputMinWidth === 'auto'
              ? 'auto'
              : `${inputMinWidth}px`
          }
        />
      )}
    />
  );
}
